export const useSidebarStore = defineStore(
  'sidebar',
  () => {
    const expanded = ref(false)

    const isExpanded = computed(() => expanded.value)

    function switchExpanded() {
      expanded.value = !expanded.value
    }

    return { isExpanded, switchExpanded, expanded }
  },
  {
    persist: true,
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSidebarStore, import.meta.hot))
}
